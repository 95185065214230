import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import PortfolioHome from "@/components/portfolio-home";
import ServiceTwo from "@/components/service-two";
import AboutTwo from "@/components/about-two";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import SliderOne from "@/components/slider-one";
import Tribes from "@/components/service-three";
import ServiceThree from "@/components/starelders/service-three";

import StarMap from "../assets/images/starmap.jpg";

const HomeOne = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Home One">
          <HeaderOne />
          <SliderOne />
          <AboutTwo />
          <ServiceThree />
          <PortfolioHome />
          <Tribes />
          <div id="starmap" className="starmap">
            <h2 className="sec_title">Starmap</h2>
            <img src={StarMap} alt="Starmap" />
          </div>          
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default HomeOne;
