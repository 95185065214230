import React from "react";
import { Link } from "gatsby";

const ServiceCardThree = ({ data }) => {
  const { title, image, text, url } = data;
  return (
    <>
      <div className="single_wedo">
        <img src={image} alt={title} />
        <div className="overlay_wedo">
          <p className="vessel_title">{title}</p>
          <p>{text}</p>
        </div>
      </div>
    </>
  );
};

export default ServiceCardThree;
